import { Accordion, AccordionButton, AccordionItem, AccordionPanel } from "@solid-reach/accordion";
import { Show, createSignal } from "solid-js";
import { HomeFAQSection } from "../../model";

export default function FAQSection({ questions }: HomeFAQSection) {
	const [questionOpen, setQuestionOpen] = createSignal(0);
	const handleClick = ({ currentTarget }) => {
		const id = currentTarget?.value;
		id != questionOpen() ? setQuestionOpen(id) : setQuestionOpen(0);
	};

	return (
		<section class="flex flex-col bg#paper items-center pt-80px text-16px">
			<p class="text#p text-31px font-bold leading-34px">Frequently Asked Questions</p>
			<div class="flex flex-col gap-10px my-40px w-806px">
				{questions.map((question) => {
					return (
						<Accordion collapsible class="border-2 border#p border-solid p-20px">
							<AccordionItem>
								<AccordionButton class="w-full cursor-pointer" onClick={handleClick} value={`${question.id}`}>
									<div class="flex justify-between items-center">
										<p class="text-16px font-bold leading-24px">{question.question}</p>
										<Show
											when={questionOpen() == question.id}
											fallback={<i class="icon-local:arrow-right rotate-180 w-25px h-25px" />}
										>
											<i class="icon-local:down-arrow w-25px h-25px" />
										</Show>
									</div>
								</AccordionButton>
								<AccordionPanel>
									<p class="pt-8px">{question.answer}</p>
								</AccordionPanel>
							</AccordionItem>
						</Accordion>
					);
				})}
			</div>
			<a class="mb-80px border-2 border#p border-solid py-10px px-15px rounded-3px cursor-pointer font-bold">See more</a>
			<hr class="max-w-screen w-screen h-2px bg#p" />
		</section>
	);
}
